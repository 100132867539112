const listToTree = (
    list,
    config = {
        identifier: 'id',
        parentIdentifier: 'parentId',
        rootParentIdentifier: null,
    },
) => {
    var map = {},
        node,
        roots = [],
        i

    for (i = 0; i < list.length; i += 1) {
        map[list[i][config.identifier]] = i
        list[i].children = []
    }

    for (i = 0; i < list.length; i += 1) {
        node = list[i]
        if (node[config.parentIdentifier] !== config.rootParentIdentifier) {
            // making sure the parent exists
            if (map.hasOwnProperty(node[config.parentIdentifier])) {
                list[map[node[config.parentIdentifier]]].children.push(node)
            } else {
                roots.push(node)
            }
        } else {
            roots.push(node)
        }
    }
    return roots
}

export default listToTree
