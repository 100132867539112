import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import { TreeView, Skeleton } from '@material-ui/lab'
import { ReactComponent as PlusSquareIconSvg } from './Resources/collapse.svg'
import { ReactComponent as MinusquareIconSvg } from './Resources/constrain.svg'
import MainTreeItem from './MainTreeItem'
import SubTreeItem from './SubTreeItem'
import { isNilOrEmpty } from '../../core'
import config from 'config'

const getFullImageUrl = (imagePath) => `${config.BASE_EID_URL}${imagePath}`

const useStyles = makeStyles({
    root: {
        '&:hover > content': {},
        height: '100%',
        flexGrow: 1,
    },
})

export default function Tree({
    data,
    handleSelect,
    loading,
    selectedItem,
    searchTerm,
    idProp = 'id',
    labelProp = 'friendlyName',
    ...rest
}) {
    const [expanded, setExpanded] = useState([])

    useEffect(() => {
        if (!isNilOrEmpty(searchTerm)) {
            setExpanded(data.map((d) => d[idProp]))
        }
    }, [searchTerm])

    const onNodeToggle = (event, nodeIds) => {
        setExpanded(nodeIds)
    }

    const classes = useStyles()

    const renderRootNodes = (node) => {
        const isParentSelected =
            selectedItem && selectedItem[idProp] === node[idProp]
        return (
            <MainTreeItem
                key={node[idProp]}
                nodeId={node[idProp]}
                label={node[labelProp]}
                count={
                    node.children.length > 0 ? node.children.length : undefined
                }
                highlightText={searchTerm}
                selected={selectedItem && selectedItem[idProp] === node[idProp]}
                onClick={(event) => {
                    event.preventDefault()
                    handleSelect(node)
                }}
                iconUrl={
                    node.iconUrl && node.iconUrl.length > 0
                        ? getFullImageUrl(node.iconUrl)
                        : null
                }
                {...rest}
            >
                {Array.isArray(node.children) &&
                    node.children.map((currentNode) =>
                        renderSubNodes(currentNode, isParentSelected),
                    )}
            </MainTreeItem>
        )
    }

    const renderSubNodes = (node, isParentSelected) => {
        const _isParentSelected = isParentSelected
            ? isParentSelected
            : selectedItem && selectedItem[idProp] === node[idProp]
        return (
            <SubTreeItem
                key={node[idProp]}
                nodeId={node[idProp]}
                label={node[labelProp]}
                count={
                    node.children.length > 0 ? node.children.length : undefined
                }
                highlightText={searchTerm}
                selected={selectedItem && selectedItem[idProp] === node[idProp]}
                onClick={(event) => {
                    event && event.preventDefault()
                    handleSelect(node)
                }}
                iconUrl={
                    node.iconUrl && node.iconUrl.length > 0
                        ? getFullImageUrl(node.iconUrl)
                        : null
                }
                isParentSelected={isParentSelected}
                {...rest}
            >
                {Array.isArray(node.children) &&
                    node.children.map((currentNode) =>
                        renderSubNodes(currentNode, _isParentSelected),
                    )}
            </SubTreeItem>
        )
    }

    return loading ? (
        <>
            <Box margin="4px 8px">
                <Skeleton animation="wave" variant="rect" height={20} />
            </Box>
            <Box margin="4px 8px">
                <Skeleton animation="wave" variant="rect" height={20} />
            </Box>
        </>
    ) : (
        data && (
            <TreeView
                className={classes.root}
                defaultCollapseIcon={<MinusquareIconSvg />}
                defaultExpandIcon={<PlusSquareIconSvg />}
                expanded={expanded}
                onNodeToggle={onNodeToggle}
                disableSelection
            >
                {data.map((item, index) => (
                    <React.Fragment key={item[idProp]}>
                        {renderRootNodes(item)}
                    </React.Fragment>
                ))}
            </TreeView>
        )
    )
}

Tree.propTypes = {
    data: PropTypes.array,
    handleSelect: PropTypes.func.isRequired,
}

Tree.defaultProps = {}
