import React from 'react'
import PropTypes from 'prop-types'
import { Box, styled } from '@material-ui/core'
import { Icon } from '../../eid-icons'

const styles = {
    small: {
        minHeight: '35px',
        lineHeight: '1 !important',
    },
    medium: {
        height: '30px',
        padding: '5px 20px',
        lineHeight: '1 !important',
    },
    large: {
        minHeight: '36px',
        padding: '5px 20px',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '1 !important',
    },
}
const statuses = {
    Completed: {
        colors: {
            backgroundColor: '#e7f4ff',
            color: '#007fc7',
        },

        icon: <Icon name="CompletedStatus" />,
        gradient: 'linear-gradient(to right, #30acc1 0%, #3077c1 100%)',
    },

    Rejected: {
        colors: {
            backgroundColor: '#f9dada',
            color: '#d0021b',
        },
        icon: <Icon name="RejectedStatus" />,
        gradient: 'linear-gradient(to right, #d00245 0%, #d64d00 100%)',
    },
    Open: {
        colors: {
            backgroundColor: '#f9eada',
            color: '#e56300',
        },
        icon: <Icon name="OpenStatus" />,
        gradient: 'linear-gradient(to right, #eda300 0%, #d66a00 100%)',
    },
    Open_PendingApproval: {
        colors: {
            backgroundColor: '#f9eada',
            color: '#e56300',
        },
        icon: <Icon name="OpenStatus" />,
        gradient: 'linear-gradient(to right, #eda300 0%, #d66a00 100%)',
    },
    Open_PendingItemApproval: {
        colors: {
            backgroundColor: '#f9eada',
            color: '#e56300',
        },
        icon: <Icon name="OpenStatus" />,
        gradient: 'linear-gradient(to right, #eda300 0%, #d66a00 100%)',
    },
    NotStarted: {
        colors: {
            backgroundColor: '#aab0b4',
            color: '#5b6871',
        },
        icon: <Icon name="NotStartedStatus" />,
        gradient: 'linear-gradient(to right, #5d6870, #90a0ab)',
    },
    NotReady: {
        colors: {
            backgroundColor: '#aab0b4',
            color: '#5b6871',
        },
        icon: <Icon name="NotStartedStatus" />,
        gradient: 'linear-gradient(to right, #5d6870, #90a0ab)',
    },
    Error: {
        colors: {
            backgroundColor: '#d0021b',
            color: '#ffffff',
        },
        icon: <Icon name="ErrorStatus" color="#ffffff" />,
        gradient: 'linear-gradient(to right, #8a0111 0%, #af2000 100%)',
    },
    Expired: {
        colors: {
            backgroundColor: '#d0021b33',
            color: '#d0021b',
        },
        icon: <Icon name="ErrorStatus" color="#d0021b" />,
        gradient: 'linear-gradient(to right, #8a0111 0%, #af2000 100%)',
    },
    Approved: {
        colors: {
            backgroundColor: '#e1fee6',
            color: '#1eb77b',
        },
        icon: <Icon name="ApprovedStatus" color="#1EB758" />,
        gradient: 'linear-gradient(to right, #1eb758 0%, #1eb7a6 100%)',
    },
    PartiallyApproved: {
        colors: {
            backgroundColor: '#e1fee6',
            color: '#1eb77b',
        },
        icon: <Icon name="ApprovedStatus" color="#1EB758" />,
        gradient: 'linear-gradient(to right, #1eb758 0%, #1eb7a6 100%)',
    },
    Canceled: {
        colors: {
            backgroundColor: '#5d6870',
            color: '#ffffff',
        },
        icon: <Icon name="CanceledStatus" color="#ffffff" />,
    },
    WaitingOnGlobalFulfillment: {
        colors: {
            backgroundColor: '#fff7e5',
            color: '#ffb800',
        },
        icon: <Icon name="PendingStatus" color="#ffb800" />,
    },
    Approve: {
        colors: {},
        gradient: ' linear-gradient(101deg, #01ae8f, #30c17c)',
    },
    Reject: {
        gradient: 'linear-gradient(102deg, #ae0142, #c14830 100%)',
    },
    Continued: {
        icon: (
            <Icon
                name="ArrowRoundedBorders"
                color="#307fc1"
                fill="#307fc1"
                height="18px"
                direction="right"
            />
        ),
        colors: { backgroundColor: '#e7f4ff', color: '#307fc1' },
    },
    Deferred: {
        icon: (
            <Icon
                name="ArrowCurvedRoundedBorders"
                color="#ffb800"
                fill="#ffffff"
                height="18px"
                direction="right"
            />
        ),
        colors: { backgroundColor: '#5d6870', color: '#ffffff' },
    },
    Delegated: {
        icon: (
            <Icon
                name="ArrowCurvedRoundedBorders"
                color="#ffb800"
                fill="#307fc1"
                height="20px"
                direction="right"
            />
        ),
        colors: { backgroundColor: '#e7f4ff', color: '#307fc1' },
    },
    VotedYes: {
        icon: <Icon name="ThumbsUp" height="18px" />,
        colors: { backgroundColor: '#e1fee6', color: '#1eb77b' },
    },
    VotedNo: {
        icon: <Icon name="ThumbsDown" height="18px" />,
        colors: { backgroundColor: '#f9dada', color: '#d0021b' },
    },
    ReturnedToSender: {
        icon: <Icon name="ErrorStatus" />,
        colors: { backgroundColor: '#f9dada', color: '#d0021b' },
    },
    Requested: {
        icon: (
            <Icon
                name="ArrowRoundedBorders"
                color="#ffb800"
                fill="#307fc1"
                height="20px"
                direction="right"
            />
        ),
        colors: { backgroundColor: '#e7f4ff', color: '#307fc1' },
    },
    Waiting: {
        icon: <Icon name="PauseRoundedBorders" />,
        colors: { backgroundColor: '#d8d8dd', color: '#5d6870' },
    },
    Submit: {
        icon: (
            <Icon
                name="CheckWithWhiteCircle"
                color="red"
                fill="#ffffff"
                height="20px"
            />
        ),
        colors: { backgroundColor: '#307fc1', color: '#ffffff' },
    },
    Back: {
        icon: (
            <Icon
                name="ArrowRoundedBorders"
                fill="#ffffff"
                height="18px"
                direction="left"
            />
        ),
        colors: { backgroundColor: '#307fc1', color: '#ffffff' },
    },
    Next: {
        icon: (
            <Icon
                name="ArrowRoundedBorders"
                fill="#ffffff"
                height="18px"
                direction="right"
            />
        ),
        colors: { backgroundColor: '#307fc1', color: '#ffffff' },
    },
    Default: {
        colors: {
            backgroundColor: '#d8d8dd',
            color: '#5d6870',
        },
        gradient: 'none',
    },
}

const MiniTag = styled('span')(({ image, width, style }) => ({
    display: 'table',
    padding: '0px 8px',
    borderRadius: '5px',
    backgroundColor: '#5d6870',
    textTransform: 'uppercase',
    fontSize: '12px',
    color: '#ffffff',
    backgroundImage: image,
    minWidth: width,
    ...style,
}))

const StyledDiv = styled('div')(({ colors, size, minWidth }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors && colors.backgroundColor,
    color: colors ? colors.color : 'black',
    border: ` solid 1px ${colors ? colors.color : 'black'}`,
    borderRadius: '6px',
    fontSize: '13px',
    overflow: 'hidden !important',
    overflowX: 'hidden',
    ...styles[size],
    minWidth: minWidth ? minWidth : '154px',
}))

const StatusIndicator = (props) => {
    const { size, variant, label, width, style, minWidth } = props
    const variantToUse = variant === 'PreApproved' ? 'Approved' : variant
    const status = statuses[variantToUse] || statuses['Default']

    return size === 'small' ? (
        <MiniTag image={status?.gradient} width={width} style={style}>
            {label}
        </MiniTag>
    ) : (
        <StyledDiv colors={status?.colors} size={size} minWidth={minWidth}>
            <Box display="flex">{status?.icon}</Box>
            <div style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                {label}
            </div>
        </StyledDiv>
    )
}

StatusIndicator.propTypes = {
    variant: PropTypes.oneOf([
        'NotStarted',
        'NotReady',
        'Approved',
        'PreApproved',
        'Completed',
        'Open',
        'Open_PendingItemApproval',
        'Open_PendingApproval',
        'Rejected',
        'Error',
        'Reject',
        'Approve',
        'Default',
        'Expired',
        'Canceled',
        'PartiallyApproved',
        'WaitingOnGlobalFulfillment',
        'Continued',
        'Deferred',
        'Delegated',
        'VotedYes',
        'VotedNo',
        'ReturnedToSender',
        'Requested',
        'Waiting',
        'Submit',
        'Back',
        'Next',
    ]),
    size: PropTypes.string,
    label: PropTypes.string,
    style: PropTypes.object,
}

StatusIndicator.defaultProps = {
    variant: 'Default',
}

export default StatusIndicator
