import React from 'react'
import TreeItem from '@material-ui/lab/TreeItem'
import { makeStyles, Box, Typography, Radio, styled } from '@material-ui/core'
import { Icon } from '../../eid-icons'

const StyledRadio = styled(Radio)({
    padding: '2px',
    maxHeight: '20px',
    '&:hover': {
        backgroundColor: '#d2d2d9',
    },
})

const useStyles = makeStyles({
    root: {
        '&:focus > $content': {
            backgroundColor: 'inherit',
        },
    },

    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 12,
    },
    label: (props) => ({
        color: props.radioSelection
            ? props.selected
                ? '#000000'
                : '#767676'
            : props.selected
            ? '#00bb75'
            : '#3b454d',
        width: 'max-content',
        borderRadius: '5px',
        backgroundColor: !props.radioSelection && 'unset !important',
        opacity: !props.radioSelection && props.isParentSelected ? 0.4 : 1,
        marginLeft: !props.radioSelection && '3px',

        border:
            !props.radioSelection && props.selected
                ? 'solid 1px #00bb75'
                : 'none',
        padding: props.radioSelection ? '5px' : '0px',
        '&:hover': {
            backgroundColor: '#f1f1f4 !important',
        },
        '& .search-highlight': {
            color: '#01745f',
            fontWeight: 600,
            textDecoration: 'underline',
        },
    }),
    content: {
        marginBottom: '6px',
        marginTop: '6px',

        '&:hover': {
            backgroundColor: 'inherit',
        },
    },
})

const highlight = (text, searchKey) => {
    searchKey = new RegExp('(' + searchKey + ')', 'gi')
    return text.split(searchKey).map((o, i) =>
        i % 2 === 1 ? (
            <span className="search-highlight" key={i}>
                {o}
            </span>
        ) : (
            o
        ),
    )
}

const SubTreeItem = ({
    index,
    label,
    count,
    highlightText,
    selected,
    onClick,
    radioSelection = true,
    iconUrl,
    isParentSelected,
    ...rest
}) => {
    const classes = useStyles({ selected, isParentSelected, radioSelection })

    let labelToShow

    if (highlightText && highlightText.length) {
        labelToShow = highlight(label, highlightText)
    } else {
        labelToShow = label
    }

    return (
        <TreeItem
            classes={classes}
            {...rest}
            label={
                <Box
                    padding={radioSelection ? '0px' : '5px'}
                    display="flex"
                    height="100%"
                    alignItems="center"
                    onClick={(e) => {
                        if (!radioSelection) {
                            e.preventDefault()
                            onClick(e)
                        } else {
                            onClick()
                        }
                    }}
                >
                    {radioSelection && (
                        <StyledRadio
                            checkedIcon={<Icon name="RadioFilled" />}
                            icon={<Icon name="Radio" />}
                            checked={selected ?? false}
                            onClick={onClick}
                        />
                    )}
                    {iconUrl && (
                        <img style={{ maxHeight: '16px' }} src={iconUrl} />
                    )}
                    <Typography
                        variant="body2"
                        style={{
                            wordBreak: 'break-all',
                            paddingLeft: '12px',
                        }}
                    >
                        {labelToShow}
                    </Typography>
                    {count !== undefined && (
                        <Typography
                            variant="body2"
                            style={{ marginLeft: '5px', color: '#919193' }}
                        >
                            ({count})
                        </Typography>
                    )}
                    {!radioSelection && (selected || isParentSelected) && (
                        <Box style={{ marginLeft: '17px', marginRight: '8px' }}>
                            <Icon name="Check" color={'#00bb75'} />
                        </Box>
                    )}
                </Box>
            }
        />
    )
}

export default SubTreeItem
