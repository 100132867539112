import { FC, forwardRef, useState } from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { createStyles } from '@material-ui/core/styles'
import { Icon } from '../../eid-icons'
import { Modal } from './Modal'
import { WorkflowIFrame } from './WorkflowIFrame'
import classNames from 'classnames'

const useStyles = makeStyles(
    createStyles({
        button: {
            '&:hover': {
                cursor: 'pointer',
            },
        },
        modalHeader: {
            padding: '20px',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            backgroundColor: '#307fc1',
            backgroundImage: 'linear-gradient(105deg, #682a94, #05afe0)',
        },
        content: {
            color: '#000000',
            marginTop: '64px',
        },
        title: {
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#ffffff',
        },
        closeIcon: {
            position: 'absolute',
            right: 20,
            top: 16,
            height: '10px',
            cursor: 'pointer',
        },
    }),
)

export type EidWorkflowProps = {
    baseEidUrl?: string
    workflowUrl?: string
    title: string
    workflowName?: string
    workflowParams?: string[]
    onComplete?: () => void
    className?: string
}

export const EidWorkflow: FC<EidWorkflowProps> = forwardRef((props, ref) => {
    const classes = useStyles()

    const [showModal, setShowModal] = useState(false)

    const header = (
        <Box className={classes.modalHeader}>
            <Typography className={classes.title}>{props.title}</Typography>
            <Box className={classes.closeIcon}>
                <Icon
                    name="Close"
                    color="#ffffff"
                    onClick={() => setShowModal(false)}
                />
            </Box>
        </Box>
    )

    return (
        // @ts-ignore
        <div ref={ref}>
            <Modal open={showModal} header={header}>
                <Box className={classes.content}>
                    {showModal && (
                        <WorkflowIFrame
                            baseEidUrl={props.baseEidUrl}
                            workflowUrl={props.workflowUrl}
                            workflowName={props.workflowName}
                            workflowParams={props.workflowParams}
                            onComplete={() => {
                                setShowModal(false)
                                if (props.onComplete) {
                                    props.onComplete()
                                }
                            }}
                        />
                    )}
                </Box>
            </Modal>
            <Box
                onClick={() => setShowModal(true)}
                className={classNames(classes.button, props.className)}
            >
                {props.children}
            </Box>
        </div>
    )
})
