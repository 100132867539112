import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { useSwrApiGet, listToTree } from '../../core'
import { Tree, TextInput } from '../../eid-ui'

const contains = (text, searchTerm) => {
    if (!text) {
        return false
    }
    return text.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
}

const search = (items, searchTerm) => {
    const filteredItems = items.reduce((acc, item) => {
        if (
            contains(item.friendlyName, searchTerm) ||
            contains(item.name, searchTerm)
        ) {
            acc.push(item)
        } else if (item.children && item.children.length > 0) {
            let newItems = search(item.children, searchTerm)
            if (newItems && newItems.length > 0) {
                acc.push({
                    ...item,
                    children: newItems,
                })
            }
        }
        return acc
    }, [])

    return filteredItems
}

const EidTree = ({
    url,
    dedupingInterval = 2000,
    onChange,
    identifier = 'id',
    parentIdentifier = 'parentId',
    rootParentIdentifier = null,
    value = null,
    searchable = false,
    searchPlaceholder,
    initialValue,
    treeContainerStyle = {},
    searchContainerStyle = {},
    ...rest
}) => {
    const [treeItems, setTreeItems] = useState([])

    const { data } = useSwrApiGet(url, {
        dedupingInterval,
    })

    const [searchKey, setSearchKey] = useState('')

    useEffect(() => {
        if (data) {
            let itemsToSet

            if (searchKey !== '') {
                itemsToSet = search(
                    listToTree(data.data, {
                        identifier,
                        parentIdentifier,
                        rootParentIdentifier,
                    }),
                    searchKey,
                )
            } else {
                itemsToSet = listToTree(data.data, {
                    identifier,
                    parentIdentifier,
                    rootParentIdentifier,
                })
            }
            setTreeItems(itemsToSet)
        }
    }, [data, searchKey])

    useEffect(() => {
        if (data && !value && initialValue) {
            onChange(data.data.find((i) => i.id === initialValue))
        }
    }, [initialValue, value, data])

    return (
        <>
            {searchable && (
                <Box {...searchContainerStyle} marginBottom="15px">
                    <TextInput
                        placeholder={searchPlaceholder}
                        value={searchKey}
                        handleChange={(e) => setSearchKey(e.target.value)}
                    />
                </Box>
            )}
            <Box paddingX="10px" {...treeContainerStyle}>
                <Tree
                    data={treeItems}
                    searchTerm={searchKey}
                    loading={!data}
                    handleSelect={(item) => {
                        onChange(item)
                    }}
                    selectedItem={value}
                    {...rest}
                ></Tree>
            </Box>
        </>
    )
}

EidTree.propTypes = {
    /**
     * URL where to fetch the data from.
     */
    url: PropTypes.string.isRequired,

    /**
     * Callback when there is a change in the value.
     */
    onChange: PropTypes.func,

    /**
     * Request deduplication interval (ms) for requests with the same key.
     * Default is set to 2000.
     */
    dedupingInterval: PropTypes.number,

    /**
     * Property name that uniquely identifies an item in the tree.
     */
    identifier: PropTypes.string,

    /**
     * Property name that indicates the parentId of an item.
     */
    parentIdentifier: PropTypes.string,

    /**
     * Value of the {parentIdentifier} property for root items.
     */
    rootParentIdentifier: PropTypes.any,
}

export default EidTree
