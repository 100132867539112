import { Box } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useAuthState } from '../../core/auth/context'
import ProgressBar from '../ProgressBar'

const getIframeStyles = (height?: string) => ({
    border: 'none',
    width: '100%',
    height: height ? height : '78vh',
    margin: 0,
    padding: 0,
})

export type WorkflowIFrameProps = {
    baseEidUrl?: string
    workflowUrl?: string
    workflowName?: string
    workflowParams?: string[]
    onComplete: () => void
    height?: string
    loadingMessage?: string
}

export const WorkflowIFrame = (props: WorkflowIFrameProps) => {
    const [{ isWorkflowIRenderable }]: any = useAuthState()
    const [progress, setProgress] = useState(0)
    let progressInterval: any = null

    useEffect(() => {
        const eventListener = (e: WindowEventMap['message']) => {
            if (e.origin === props.baseEidUrl) {
                console.log(e.data) // Keeping the logger to check if end/cancel events get triggered
                if (e.data.type === 'WorkflowEnded') {
                    setTimeout(props.onComplete, 3000)
                } else if (e.data.type === 'WorkflowCanceled') {
                    props.onComplete()
                }
            }
        }

        window.addEventListener('message', eventListener)

        return () => {
            window.removeEventListener('message', eventListener)
        }
    }, [])
    useEffect(() => {
        if (!isWorkflowIRenderable && progress < 100) {
            progressInterval = setInterval(() => {
                setProgress((pre) => Math.round(pre + 3.33))
            }, 1000)
        }
        return () => {
            clearInterval(progressInterval)
        }
    }, [progress])

    if (!isWorkflowIRenderable) {
        return (
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '80px',
                    width: '100%',
                }}
            >
                <Box style={{ width: '200px' }}>
                    <ProgressBar
                        label={props.loadingMessage}
                        completed={progress}
                        height={16}
                        showPercentage={false}
                        showAnimation={true}
                    />
                </Box>
            </Box>
        )
    }

    let fullWorkflowUrl

    if (props.workflowUrl) {
        fullWorkflowUrl = props.workflowUrl
    } else {
        fullWorkflowUrl = constructApprovalWorkflowUrl(
            props.baseEidUrl,
            props.workflowName,
            props.workflowParams,
        )
    }

    return (
        <iframe
            title={`EmpowerID ${props.workflowName}`}
            style={getIframeStyles(props.height)}
            src={fullWorkflowUrl}
        />
    )
}

const constructApprovalWorkflowUrl = (
    baseEidUrl?: string,
    workflowName?: string,
    workflowParams?: string[],
) => {
    let queryParams = ['popupNav=true']

    if (workflowParams) {
        queryParams = queryParams.concat(workflowParams)
    }

    const queryString = queryParams.join('&')

    return `${baseEidUrl}/ui?hideHeader=true&wfMsContext=true&wfMsUrl=${window.location.origin}#w/${workflowName}?${queryString}`
}
