import React, { useEffect } from 'react'
import { useAuthState } from 'packages/core'
import { Loader } from 'packages/eid-ui'
import { isNilOrEmpty, useQuery } from 'packages/core'
import {
    useCurrentPerson,
    useApplicationSettings,
    useAccessSubcomponents,
    useAllApplications,
    useTranslations,
} from 'hooks'
import moment from 'moment'
import i18n from 'i18n'
import { isAppRunningInIframe } from 'packages/core'
import config from 'config'

const useLoadCurrentPerson = () => {
    const { data } = useCurrentPerson()

    return !isNilOrEmpty(data)
}

const useLoadApplicationSettings = () => {
    let settingsOverride = config?.UI_SETTINGS_OVERRIDE
    if (isAppRunningInIframe()) {
        settingsOverride = 'ServiceNow'
    }

    const { data } = useApplicationSettings(settingsOverride)

    return !isNilOrEmpty(data)
}

const useLoadAccessSubcomponents = () => {
    const [{ access }, dispatchAuthState] = useAuthState()
    const { data } = useAccessSubcomponents()

    useEffect(() => {
        if (data) {
            dispatchAuthState({
                type: 'SET_ACCESS_SUBCOMPONENTS',
                payload: data,
            })
        }
    }, [data, dispatchAuthState])

    return access !== null
}

const useLoadAllApplications = () => {
    const { data: applications } = useAllApplications()

    return !isNilOrEmpty(applications)
}

const useLoadTranslations = () => {
    const query = useQuery()
    const debugLocalization = query.get('debugLocalization') === 'true'

    const { data } = useTranslations()
    const { data: currentPerson } = useCurrentPerson()

    let locale = 'en'
    if (currentPerson?.languageCode) {
        locale = currentPerson?.languageCode
    }

    useEffect(() => {
        i18n.changeLanguage(locale)
        moment.locale(locale)
    }, [locale])

    useEffect(() => {
        if (data) {
            const localeData = data.reduce((current, r) => {
                if (debugLocalization) {
                    current[r.key] = r.key
                } else {
                    current[r.key] = r.translatedValue
                }

                return current
            }, {})

            i18n.addResourceBundle(locale, 'translation', localeData)
        }
    }, [data, locale, debugLocalization])

    return Boolean(data)
}

const withRequiredData = (ChildComponent) => (props) => {
    const currentPersonLoaded = useLoadCurrentPerson()
    const applicationSettingsLoaded = useLoadApplicationSettings()
    const accessSubcomponentsLoaded = useLoadAccessSubcomponents()
    const allApplicationsLoaded = useLoadAllApplications()
    const translationsLoaded = useLoadTranslations()

    if (
        !currentPersonLoaded ||
        !applicationSettingsLoaded ||
        !accessSubcomponentsLoaded ||
        !allApplicationsLoaded ||
        !translationsLoaded
    )
        return <Loader />

    return <ChildComponent {...props} />
}

export default withRequiredData
