import moment from "moment";
// getSpecificDate(12, 1, 2015);
export const getDaysInMonth = (month, year) => {
  return moment(`${month}-${year}`, "MM-YYYY").daysInMonth();
};

export const getFirstWeekdayOfMonth = (month, year) => {
  return moment(`${month}-${year}`, "MM-YYYY").startOf("month").isoWeekday();
};

export const getPrevMonthYear = (month, year) => {
  // If it is January... prev month is Dec of the previous year
  if (month === 1) {
    return {
      month: 12,
      year: year - 1,
    };
  }

  // Otherwise, same year, but month - 1
  return {
    month: month - 1,
    year,
  };
};

export const getNextMonthYear = (month, year) => {
  // If it is January... prev month is Dec of the previous year
  if (month >= 1 && month < 12) {
    return {
      month: month + 1,
      year,
    };
  }

  // Otherwise, same year, but month - 1
  return {
    month: 1,
    year: year + 1,
  };
};

export const getDatesInMonthDisplay = (month, year) => {
  const daysInMonth = getDaysInMonth(month, year);

  const firstWeekday = getFirstWeekdayOfMonth(month, year);
  const result = [];

  const prev = getPrevMonthYear(month, year);

  const prevDaysInMonth = getDaysInMonth(prev.month, prev.year);

  // Add prev overflow dates...

  for (let j = firstWeekday - 2; j >= 0; j--) {
    result.push({
      date: moment(
        `${prev.month}-${prevDaysInMonth - j}-${prev.year}`,
        "MM-DD-YYYY"
      ).toDate(),
      currentMonth: false,
    });
  }

  // Add current month's dates
  for (let i = 1; i <= daysInMonth; i++) {
    result.push({
      date: moment(`${month}-${i}-${year}`, "MM-DD-YYYY").toDate(),
      currentMonth: true,
    });
  }

  // Overflow dates for next month to meet 42 days per month display   requirement
  if (result.length <= 35) {
    const daysToAdd = 35 - result.length;
    const next = getNextMonthYear(month, year);

    for (let k = 1; k <= daysToAdd; k++) {
      result.push({
        date: moment(`${next.month}-${k}-${next.year}`, "MM-DD-YYYY").toDate(),
        currentMonth: false,
      });
    }
  } else if (result.length < 42) {
    const daysToAdd = 42 - result.length;
    const next = getNextMonthYear(month, year);

    for (let k = 1; k <= daysToAdd; k++) {
      result.push({
        date: moment(`${next.month}-${k}-${next.year}`, "MM-DD-YYYY").toDate(),
        currentMonth: false,
      });
    }
  }

  return result;
};

export const getMonthSet = (selectDate) => {
  const month = getMonth(selectDate) + 1;
  const result = {
    current: selectDate,
    prev: getSpecificDate(month - 1, getToday(), getYear(selectDate)),
    next: getSpecificDate(month + 1, getToday(), getYear(selectDate)),
  };

  if (month === 1) {
    result.prev = getSpecificDate(12, 1, getYear(selectDate) - 1);
  }

  if (month === 12) {
    result.next = getSpecificDate(1, 1, getYear(selectDate) + 1);
  }

  return result;
};

export const getSpecificDate = (month, dayOfMonth, year) => {
  return moment(`${month}-${dayOfMonth}-${year}`, "MM-DD-YYYY").toDate();
};

export const getDayOfMonth = (date) => moment(date).date();

export const getMonth = (date) => moment(date).month();

export const getMonth2 = (date) => {
  return moment(date).month();
};

export const getYear = (date) => moment(date).year();

export const getToday = () => moment().toDate();

export const getHour = (date) =>
  moment(date).format("a") === "am"
    ? moment(date).format("h") == "12"
      ? "12"
      : moment(date).format("h")
    : moment(date).format("h");
export const getMin = (date) => moment(date).format("mm");
export const getAMPM = (date) => moment(date).format("a");

export const getReadableWeekday = (date) => moment(date).format("dddd");

export const getReadableMonthDate = (date) => moment(date).format("MMMM Do");

export const getMonthDayYear = (date) => moment(date).format("MM-DD-YYYY");

const startOfWeek = moment(new Date())
  .startOf("isoWeek")
  .subtract(1, "weeks")
  .format("MM-DD-YYYY");
const endOfWeek = moment(startOfWeek).add(6, "day").format("MM-DD-YYYY");

export const lastWeekDate = [startOfWeek, endOfWeek];

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const inTimeRange = (x, a, b, granularity) =>
  moment(x).isBetween(a, b, granularity);
//  ||  moment(x).isSame(a, granularity) ||
// moment(x).isSame(b, granularity);

const currentYear = new Date().getFullYear();
const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
export const getYears = () => {
  return range(currentYear + 50, currentYear - 50, -1);
};
