import DrawerActionButton from './DrawerActionButton'
import { Box, Typography } from '@material-ui/core'
import { useHistory } from 'react-router'
import { Icon } from 'packages/eid-icons'

const BackButton = ({ label }) => {
    const history = useHistory()
    if (label) {
        return (
            <DrawerActionButton
                width="auto"
                fontColor="#307fc1"
                border="solid 1px #307fc1"
                bgColor="transparent"
                height="40px"
                paddingRight="20px"
                onClick={() => {
                    history.goBack()
                }}
            >
                <Box display="flex" alignItems="center" paddingRight="10px">
                    <Icon
                        name="Arrow"
                        direction="left"
                        style={{
                            cursor: 'pointer',
                        }}
                        color="#307FC1"
                    />
                </Box>
                <Box display="flex" alignItems="center">
                    <Typography style={{ color: '#307fc1' }}>
                        {label}
                    </Typography>
                </Box>
            </DrawerActionButton>
        )
    }
    return (
        <Box
            width="70px"
            display="flex"
            justifyContent="flex-end"
            marginRight="10px"
        >
            <Icon
                name="BackButton"
                style={{
                    cursor: 'pointer',
                }}
                fill="#307FC1"
                onClick={() => {
                    history.goBack()
                }}
            />
        </Box>
    )
}

export default BackButton
