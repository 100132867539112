import React, { useState, useRef } from 'react'
import Button from './index'
import { Typography, Box, Backdrop, styled, MenuItem } from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import { Dropdown } from 'packages/eid-ui'

const StyledBackdrop = styled(Backdrop)(() => ({
    zIndex: '1201',
}))

const StyledMenuItem = styled(MenuItem)({
    height: '30px',
})

const CheckOutButton = ({
    children,
    disabled,
    onButtonClick,
    label,
    checkoutOptions,
    checkedOutBySomeoneElse,
    parentStyle = {},
    showPrefixIcon = false,
    showEndIcon = true,
    drawerView = false,
    ...rest
}) => {
    const [open, setOpen] = useState(false)
    const closeMenu = () => {
        setOpen((prev) => !prev)
    }
    const anchorRef = useRef(null)
    const divRef = useRef(null)

    const styles = {
        normal: {
            width: '100%',
            height: '100%',
            backgroundColor: !checkedOutBySomeoneElse ? '#307fc1' : '#aab0b4',
            color: '#fff',
            border: '0px',
            padding: '0px',
            zIndex: open ? '1202' : '0',
            '&:hover': {
                backgroundColor: !checkedOutBySomeoneElse
                    ? '#307fc1'
                    : '#aab0b4',
                cursor: !checkedOutBySomeoneElse ? 'pointer' : 'default',
            },
            '& .MuiButton-endIcon': {
                borderLeft: '1px solid rgba(255, 255, 255, 0.25)',
                height: '100%',
                marginLeft: '0px',
                marginRight: '0px',
                alignItems: 'center',
            },
            '& .MuiButton-label': {
                height: '100%',
            },
        },
        disabled: {
            backgroundColor: '#fff',
            border: 'solid 1px #ebe6e8',
            color: '#3f3f55 !important',
        },
        labelContainer: {
            width: '100%',
            padding: '0px 5px',
        },
        label: {
            fontSize: '11px',
            lineHeight: 'normal',
        },
        icon: {
            borderLeft: '1px solid rgba(255, 255, 255, 0.25)',
            display: 'flex',
            alignItems: 'center',
            width: '20%',
        },
        dropdownAnchor: {
            position: 'absolute',
            top: drawerView ? '50px' : '25px',
            left: drawerView ? '40px' : '28px',
        },
    }

    return (
        <>
            <Button
                style={{ ...styles.normal, ...parentStyle }}
                disabled={disabled}
                disabledStyles={styles.disabled}
                startIcon={
                    showPrefixIcon && (
                        <Box
                            style={{
                                position: 'relative',
                                left: '15px',
                            }}
                        >
                            <Icon
                                name="CheckWithWhiteCircle"
                                color="#d8d8dd"
                                width="20px"
                                fill="#d8d8dd"
                                direction="down"
                                size="large"
                                style={{
                                    height: '20px !important',
                                }}
                            />
                        </Box>
                    )
                }
                endIcon={
                    !disabled &&
                    checkoutOptions.length > 0 &&
                    showEndIcon && (
                        <Box
                            onClick={() => {
                                if (drawerView) {
                                    divRef.current.scrollIntoView({})
                                }

                                setOpen((prev) => !prev)
                            }}
                            style={{
                                position: 'relative',
                                display: 'flex',
                                height: '100%',
                                alignItems: 'center',
                                backgroundColor: '#307fc1',
                                cursor: 'pointer',
                                borderRadius: '0px 4px 4px 0px',
                            }}
                        >
                            <Icon
                                name="ArrowSmall"
                                color="#d8d8dd"
                                width="20px"
                                height={showPrefixIcon ? '8px' : '5px'}
                                fill="#d8d8dd"
                                direction="down"
                            />
                            <span
                                ref={anchorRef}
                                style={styles.dropdownAnchor}
                            />
                        </Box>
                    )
                }
                {...rest}
            >
                {!children ? (
                    <>
                        <Box
                            style={styles.labelContainer}
                            onClick={onButtonClick}
                        >
                            <Typography style={styles.label}>
                                {label}
                            </Typography>
                        </Box>
                    </>
                ) : (
                    children
                )}
            </Button>

            {checkoutOptions.length > 0 && (
                <>
                    <StyledBackdrop
                        sx={{ color: '#fff' }}
                        open={open}
                        onClick={closeMenu}
                    />
                    <Dropdown
                        anchorEl={anchorRef.current}
                        showCone={true}
                        keepMounted
                        open={open}
                        onClose={closeMenu}
                        listStyles={{
                            paddingBottom: '0px',
                            '& > ul': {
                                paddingBottom: '7px',
                                paddingTop: '0px',
                            },
                        }}
                        iconPosition={{
                            top: '-5px',
                            right: '0px',
                        }}
                        width="auto"
                        disableScrollLock={false}
                    >
                        {checkoutOptions.map((op, i) => {
                            return (
                                <StyledMenuItem
                                    onClick={() => {
                                        op.onClick()
                                        closeMenu()
                                    }}
                                    key={i}
                                >
                                    {op.label}
                                </StyledMenuItem>
                            )
                        })}
                    </Dropdown>
                </>
            )}
            {drawerView && <div ref={divRef}></div>}
        </>
    )
}

export default CheckOutButton
