import {
    Box,
    FormControlLabel,
    FormLabel,
    InputLabel,
    makeStyles,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
} from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import { TextInput } from 'packages/eid-ui'
import { useEffect, useState } from 'react'
import { ReactComponent as GroupIconSvg } from '../../eid-ui/RadioList/Resources/group.svg'
import { ReactComponent as OvalIconSvg } from '../../eid-ui/RadioList/Resources/oval.svg'
import classNames from 'classnames'
import MultiSelectDropdown from './Components/MultiSelectDropdown'
const ns = 'public'
const useStyles = makeStyles({
    root: {
        padding: 0,
        margin: 0,
        paddingRight: '12px',
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
    formControlLabel: {
        marginLeft: '0px',
        marginRight: '20px',
        fontSize: '12px',
        color: '#767676',
        height: '30px',
        '&:focus-within': {
            color: '#000000',
        },
    },
    dynamicSelect: {
        width: '100%',
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        padding: '8px 12px 8px 16px',
        border: ' solid 1px #d8d8dd',
        borderRadius: '5px',
        '& .MuiSelect-select:focus': {
            backgroundColor: 'white',
        },
        '&:before': {
            content: 'none !important',
        },
    },
    dynamicLabel: {
        marginTop:'15px',
        '& label': {
            paddingLeft: '0 !important',
            postion:'relative',
            top:'5px',
        },
        '& input': {
            boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
            padding: '8px 12px 8px 16px',
            border: ' solid 1px #d8d8dd',
            borderRadius: '5px',
        },
    },
    dynamicSelectBox: {
        marginTop:'20px !important',
        '& label': {
            textTransform: 'uppercase',
            color: '#b4b4b4',
            marginBottom: '5px',
        },
    },
    dyanmicRadioBox: {
        marginTop: '20px',
        '& label': {
            textTransform: 'uppercase',
            color: '#b4b4b4',
            marginBottom: '10px',
            fontSize: '12px !important',
            fontWeight: '300 !important',
        },
    },
    customFormLabel: {
        marginBottom: '20px !important',
        height: 'auto !important',
    },
    requiredLabel: {
        color: 'red !important',
      
        '& label': {
            color: 'red !important',
           
        },
    },
})
const DynamicForm = ({
    formFieldJson,
    onFieldChange,
    showErrorMessages,
    t,
}) => {
    const classes = useStyles()
    const [formData, setFormData] = useState(null)

    useEffect(() => {
        if (
            formFieldJson &&
            formFieldJson.mappingFields &&
            formFieldJson.mappingFields.length > 0 &&
            !formData
        ) {
            prepareFormDataFromJson()
        }
    }, [formFieldJson])

    const bindRequiredColor = (fieldName) => {
        if (formData) {
            const mappedField = formData.find((x) => x.name === fieldName)
            if (mappedField) {
                return mappedField.isRequired && !mappedField.value
            }
        } else {
            return false
        }
    }

    const prepareFormDataFromJson = () => {
        let formFileds = []
        formFieldJson.mappingFields.map((item) => {
            formFileds.push({
                name: item.fieldName,
                value: '',
                isRequired: item.required ? item.required : false,
                localeKey: item.localeKey,
            })
        })
        setFormData(formFileds)
    }

    const bindFields = () => {
        return formFieldJson.mappingFields.map((item, index) => {
            if (item.fieldType === 'text') {
                return (
                    <Box
                        className={classNames(classes.dynamicLabel, {
                            [classes.requiredLabel]: bindRequiredColor(
                                item.fieldName,
                            ),
                        })}
                    >
                        <TextInput
                            key={index + item.fieldName}
                            validationError={checkIfRequired(item.fieldName)}
                            value={bindValue(item.fieldName)}
                            name={item.fieldName}
                            handleChange={(e) =>
                                handleInputChange(e, item.fieldName)
                            }
                            label={
                                (item.localeKey
                                    ? t(
                                          `${ns}:MsCommonAnonymous_${item.localeKey}`,
                                      )
                                    : item.fieldName) +
                                (item.required ? ' *' : '')
                            }
                        />{' '}
                    </Box>
                )
            } else if (item.fieldType === 'dropdown') {
                return bindDropDown(item)
            } else if (item.fieldType === 'radio') {
                return bindRadioList(item)
            } else if (item.fieldType === 'multiselect') {
                return <MultiSelectDropdown bindValue={bindValue} handleDDChange={handleDDChange} t={t} item={item} ns={ns} bindRequiredColor={(itemName) => bindRequiredColor(itemName)}/>
            }
        })
    }

    const checkIfRequired = (fieldName) => {
        if (showErrorMessages && formData) {
            const mappedField = formData.find((x) => x.name === fieldName)
            if (mappedField) {
                return mappedField.isRequired && !mappedField.value
            }
        } else {
            return false
        }
    }

    const bindValue = (fieldName) => {
        if (formData) {
            const mappedField = formData.find((x) => x.name === fieldName)
            if (mappedField) {
                return mappedField.value
            }
        }
    }

    const updateFormDataOnChange = (data) => {
        let mappedFormData = [...formData]
        const findChangedMappingField = mappedFormData.findIndex(
            (x) => x.name === data.name,
        )
        if (findChangedMappingField >= 0) {
            mappedFormData[findChangedMappingField].value = data.value
            setFormData(mappedFormData)
        }
        onFieldChange(mappedFormData)
    }

    const handleInputChange = (evt, fieldName) => {
        if (evt && evt.currentTarget) {
            const changeData = {
                name: fieldName,
                value: evt.currentTarget.value,
            }
            updateFormDataOnChange(changeData)
        }
    }

    const handleradioChange = (evt) => {
        if (evt && evt.currentTarget) {
            const changeData = {
                name: evt.currentTarget.name,
                value: evt.currentTarget.value,
            }
            updateFormDataOnChange(changeData)
        }
    }

    const handleDDChange = (evt) => {
        if (evt && evt.target) {
            const changeData = {
                name: evt.target.name,
                value: evt.target.value,
            }
            updateFormDataOnChange(changeData)
        }
    }

    function StyledRadio(props) {
        return (
            <Radio
                disableRipple
                color="default"
                checkedIcon={
                    <Box display="flex" alignItems="center">
                        <GroupIconSvg></GroupIconSvg>
                    </Box>
                }
                icon={<OvalIconSvg></OvalIconSvg>}
                {...props}
            />
        )
    }

    const bindRadioList = (item) => {
        return (
            <Box className={classes.dyanmicRadioBox}>
                <FormLabel
                    component="label"
                    className={classNames(classes.customFormLabel, {
                        [classes.requiredLabel]: bindRequiredColor(
                            item.fieldName,
                        ),
                    })}
                >
                    {(item.localeKey
                        ? t(`${ns}:MsCommonAnonymous_${item.localeKey}`)
                        : item.fieldName) + (item.required ? ' *' : '')}
                </FormLabel>
                <Box marginTop="10px">
                    <RadioGroup
                        defaultValue="100"
                        aria-label="100"
                        name={item.fieldName}
                        value={'value'}
                        onChange={(e) => handleradioChange(e)}
                    >
                        {item.options.map((option, index) => (
                            <FormControlLabel
                                key={option.value + index}
                                value={option.value}
                                className={classes.formControlLabel}
                                control={<StyledRadio />}
                                label={
                                    option.localeKey
                                        ? t(
                                              `${ns}:MsCommonAnonymous_${option.localeKey}`,
                                          )
                                        : option.value
                                }
                                checked={
                                    bindValue(item.fieldName) === option.value
                                        ? true
                                        : false
                                }
                            />
                        ))}
                    </RadioGroup>
                </Box>
            </Box>
        )
    }

    const bindDropDown = (item) => {
        return (
            <Box marginTop="10px" className={classes.dynamicSelectBox}>
                <InputLabel
                    className={classNames({
                        [classes.requiredLabel]: bindRequiredColor(
                            item.fieldName,
                        ),
                    })}
                    id={`dynamic-form-dropdown-${item.fieldName}`}
                >
                    {(item.localeKey
                        ? t(`${ns}:MsCommonAnonymous_${item.localeKey}`)
                        : item.fieldName) + (item.required ? ' *' : '')}
                </InputLabel>
                <Select
                    labelId={`dynamic-form-dropdown-${item.fieldName}`}
                    id={`dynamic-form-dropdown-options-${item.fieldName}`}
                    value={bindValue(item.fieldName)}
                    onChange={(e) => handleDDChange(e)}
                    label={
                        item.localeKey
                            ? t(`${ns}:MsCommonAnonymous_${item.localeKey}`)
                            : item.fieldName
                    }
                    className={classes.dynamicSelect}
                    name={item.fieldName}
                >
                    {item.options.map((option, index) => (
                        <MenuItem key={`option-${index}`} value={option.value}>
                            {option.localeKey
                                ? t(
                                      `${ns}:MsCommonAnonymous_${option.localeKey}`,
                                  )
                                : option.value}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
        )
    }

    return (
        <Box
            width="100%"
            display="flex"
            zIndex={1}
            boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.07)"
            borderRadius="16px"
            padding="10px 31px 20px 20px"
        >
            <Box marginTop="30px" display="flex" marginRight="20px">
                <Icon
                    name="dynamicForm"
                    height="120px"
                    width="120px"
                    color="#D2D2D9"
                />
            </Box>
            <Box width="50%">
                {formFieldJson &&
                    formFieldJson.mappingFields &&
                    formFieldJson.mappingFields.length > 0 &&
                    bindFields()}
            </Box>
        </Box>
    )
}

export default DynamicForm
